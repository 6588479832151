import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    chatData: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    GET_LOG_CHAT(state, payload) {
      state.chatData = payload
    },
    ADD(state, payload) {
      state.respData.data.unshift(payload)
    },
    SEND_MESSAGE(state, payload) {
      const productIndex = state.chatData.data.findIndex(p => p._id === payload._id)
      if (productIndex < 0) {
        state.chatData.data.push(payload)
      }
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
  },
  actions: {
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/support/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getLogChat({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/support/getLogChat?contactFromProfileParnerId=${item.roomChatId}`)
          .then(response => {
            commit('GET_LOG_CHAT', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    sendMessage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/support/sendMessage', item)
          .then(response => {
            commit('SEND_MESSAGE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    setInReview({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/support/setInReview', item)
          .then(response => {
            // commit('SEND_MESSAGE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    uploadImage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/support/uploadImage', item)
          .then(response => {
            // commit('SEND_MESSAGE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
